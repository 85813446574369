<template>
	<v-form ref="form" style="width: 100%" @input="setIsValid($event)">
		<w-text-input
			v-if="withName"
			v-model="accountingPeriod.name"
			autofocus
			:label="$t('accounting_periods.name')"
			maxlength="191"
			required
			:rules="[rules.noSpecialChar]"
		/>
		<w-date-range-picker
			:allowed-dates="[allowedStart, allowedEnd]"
			:clearable="false"
			:first-date="accountingPeriod.start"
			:second-date.sync="accountingPeriod.end"
			required
			@update:first-date="updateFirstDate"
		/>
	</v-form>
</template>

<script>
import Vue from 'vue'

import Validator from '@/mixins/Validator'

export default {
	name: 'AccountingPeriodForm',
	mixins: [Validator],
	props: {
		accountingPeriods: {
			default: () => [],
			required: false,
			type: Array
		},
		isValid: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			default: () => ({
				end: null,
				start: null
			}),
			required: false,
			type: Object
		},
		withName: {
			default: true,
			required: false,
			type: Boolean
		}
	},
	computed: {
		accountingPeriod: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		max: function () {
			const start = this.accountingPeriod.start
			if (!start) {
				return undefined
			}

			const max = new Date(start)
			max.setFullYear(max.getFullYear() + 2)
			return max
		},
		min: function () {
			const end = this.accountingPeriod.end
			if (!end) {
				return undefined
			}

			const min = new Date(end)
			min.setFullYear(min.getFullYear() - 2)
			return min
		}
	},
	watch: {
		accountingPeriod: {
			handler: function () {
				this.resetValidation()
			}
		}
	},
	mounted: function () {
		this.resetValidation()
	},
	methods: {
		allowedEnd: function (value) {
			const date = new Date(value)

			if (!this.accountingPeriod.start) {
				const accountingPeriodId = this.accountingPeriod.id

				return this.accountingPeriods.every(
					accountingPeriod =>
						accountingPeriod.id == accountingPeriodId ||
						date.getTime() < new Date(accountingPeriod.start).getTime() ||
						date.getTime() > new Date(accountingPeriod.end).getTime()
				)
			} else {
				const start = new Date(this.accountingPeriod.start)
				let max = new Date(this.accountingPeriod.start)
				max.setFullYear(start.getFullYear() + 2)

				for (let i = 0; i < this.accountingPeriods.length; i++) {
					const apStart = new Date(this.accountingPeriods[i].start)

					if (apStart.getTime() < max.getTime() && apStart.getTime() > start.getTime()) {
						max = apStart
						break
					}
				}

				return date.getTime() < max.getTime()
			}
		},
		allowedStart: function (value) {
			const date = new Date(value)

			if (!this.accountingPeriod.start) {
				const accountingPeriodId = this.accountingPeriod.id

				return this.accountingPeriods.every(accountingPeriod => {
					if (accountingPeriod.id == accountingPeriodId) {
						return true
					}
					const end = new Date(accountingPeriod.end)
					const start = new Date(accountingPeriod.start)

					return date.getTime() < start.getTime() || date.getTime() > end.getTime()
				})
			} else {
				const end = new Date(this.accountingPeriod.end)
				let min = new Date(this.accountingPeriod.end)
				min.setFullYear(end.getFullYear() - 2)

				for (let i = 0; i < this.accountingPeriods.length; i++) {
					const apEnd = new Date(this.accountingPeriods[i].end)

					if (apEnd.getTime() > min.getTime() && apEnd.getTime() < end.getTime()) {
						min = apEnd
						break
					}
				}

				return date.getTime() > min.getTime()
			}
		},
		reset: function () {
			this.accountingPeriod.end = undefined
			this.accountingPeriod.start = undefined

			this.resetValidation()
		},
		resetValidation: function () {
			this.$refs.form.resetValidation()
		},
		setIsValid: function (isValid) {
			this.$emit('update:is-valid', isValid)
		},
		updateFirstDate: function (firstDate) {
			this.accountingPeriod.start = firstDate
			if (this.accountingPeriod.end || !firstDate) {
				return
			}
			const end = new Date(firstDate)
			end.setFullYear(end.getFullYear() + 1)
			end.setTime(end.getTime() - 1000 * 60 * 60 * 24)
			const date = end.toISOString().substring(0, 10)

			if (this.allowedEnd(date)) {
				if (this.accountingPeriod.hasOwnProperty('end')) {
					this.accountingPeriod.end = date
				} else {
					Vue.set(this.accountingPeriod, 'end', date)
				}
			}
		}
	}
}
</script>
